<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'staff-roles-list' }">
          Users List
        </b-link>
        for other users.

      </div>
    </b-alert>
 
    <role-edit-tab-information v-if="userData" :user-data="userData" @refetch-data="refetchData" class="mt-2 pt-75" />

  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import roleStoreModule from "../roleStoreModule";
import RoleEditTabInformation from "./RoleEditTabInformation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    RoleEditTabInformation,
},
  setup() {
    const userData = ref(null);
    const userOptions = ref([]);

    const APP_ROLE_STORE_MODULE_NAME = "app-role";

    const user = {
      id: 0
    }

    // Register module
    if (!store.hasModule(APP_ROLE_STORE_MODULE_NAME))
      store.registerModule(APP_ROLE_STORE_MODULE_NAME, roleStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_ROLE_STORE_MODULE_NAME))
        store.unregisterModule(APP_ROLE_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch("app-role/fetchUser", { id: router.currentRoute.params.id })
        .then((response) => {
          userData.value = response.data.user[0];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    };

    const fetchUserOptions = () => {
      store
        .dispatch("app-role/fetchUsers")
        .then((response) => {
          userOptions.value = response.data.roles;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userOptions.value = undefined;
          }
        });
    };

    
    onMounted(() => {
      fetchUserOptions()
    });

    refetchData()

    return {
      userData,
      userOptions,
      refetchData,
    };
  },
};
</script>

<style>

</style>
