<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col cols="12" md="3">
          <div class="d-flex mb-1">
            <h3 class="mb-0 ml-50">{{ userData.name }}</h3>
          </div>
          <div class="d-flex space">
            <h5 class="mb-0 ml-50">{{ userData.name_cn }}</h5>
          </div>
          <div
            class="d-flex space"
            v-for="role in userData.roles"
            :key="`role_${role.id}`"
          >
            <h5 class="mb-0 ml-50">
              <i>Group: {{ role.name }}</i>
            </h5>
          </div>
          <div class="d-flex space align-items-center pt-1">
            <feather-icon class="mb-0 ml-50" icon="AtSignIcon" size="14" />
            <p class="mb-0 ml-50">{{ userData.email }}</p>
          </div>
          <div class="d-flex space align-items-center pt-1">
            <feather-icon class="mb-0 ml-50" icon="CalendarIcon" size="14" />
            <p class="mb-0 ml-50">{{ userData.date_of_birth }}</p>
          </div>
        </b-col>
        <b-row class="col-md-9 flex-wrap" style="width: calc(100% + 30px)">
          <b-col cols="12" md="12" lg="5">
            <!-- Field: Select Staff-->
            <b-form-group
              label="Authorised Admin:"
              label-for="name"
              label-cols-lg="4"
            >
              <div class="form-col-select">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  v-model="authorisedName"
                  :options="userOptions"
                  :clearable="false"
                  label="name"
                  :reduce="(option) => option.id"
                  placeholder="Select Staff"
                  @input="
                    (item) => {
                      $router.push({
                        name: 'roles-edit',
                        params: { id: item },
                      });
                      $router.go();
                    }
                  "
                />
                <feather-icon size="18" icon="ChevronDownIcon" />
              </div>
            </b-form-group>
          </b-col>
          <!-- Action Buttons -->
          <b-col cols="12" md="12" lg="7">
            <div class="d-flex flex-wrap flex-sm-nowrap justify-content-end">
              <b-button
                class="px-2 mr-sm-1 mt-1 mt-sm-0 mobile-w100"
                v-if="ability.can('update', 'permissions')"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleSubmit"
                variant="primary"
              >
                <feather-icon class="mr-1" size="14" icon="SaveIcon" />Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                @click="hide"
                variant="outline-danger"
                class="mr-sm-1 mt-1 mt-sm-0 mobile-w100"
              >
                Cancel
              </b-button>
              <!-- <b-button class="px-2 mt-1 mt-sm-0 mobile-w100" variant="outline-danger">
                <feather-icon class="mr-1" size="14" icon="Trash2Icon" />Delete
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </b-row>
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="LockIcon" size="18" />
            <span class="align-middle ml-50">Permission</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="userData.directPermission"
        >
          <template v-slot:head()="data">
            <a @click="clickAll(data.label)">{{ data.label }}</a>
          </template>

          <template #cell(module)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox
              v-model="directPermission"
              :value="data.item.module + '.' + data.field.key"
              :checked="data.value"
            />
          </template>
        </b-table>
      </b-card>

      <!-- Role Permission -->
      <b-card no-body class="border mt-1">
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon icon="LockIcon" size="18" />
            <span class="align-middle ml-50">Role Permission</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="userData.rolesPermission"
        >
          <template #cell(module)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox :checked="data.value" disabled />
          </template>
        </b-table>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ref, onMounted, watch } from "@vue/composition-api";
import useStaffRolesList from "../roles-list/useStaffRolesList";
import store from "@/store";
import ability from "@/libs/acl/ability";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // handleReset() {
    //    this.updateDirectPermission();
    // },
    handleSubmit() {
      var name = this.userData.roles.name;
      if (!name) name = this.userData.roles[0].name;
      const data = {
        id: this.userData.id,
        permission: this.directPermission,
      };

      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save Changes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("app-role/setUserDirectPermission", data)
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$router.replace("/staff-roles/list");
    },
    clickAll(id) {
      const key = id.toLowerCase();
      if (this.userData.directPermission.every((item) => item[key] == true)) {
        this.userData.directPermission.forEach((item) => {
          item[key] = false;
        });
          this.updateDirectPermission();
      } else {
        this.userData.directPermission.forEach((item) => {
          item[key] = true;
        });
          this.updateDirectPermission();
      }
    },
  },
  computed: {
    authorisedName() {
      return this.userData.name;
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useStaffRolesList();

    const roleOptions = ref([]);
    const userOptions = ref([]);

    const directPermission = ref([]);

    const fetchRoleOptions = () => {
      store
        .dispatch("app-role/fetchRoles")
        .then((response) => {
          roleOptions.value = response.data.roles;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            roleOptions.value = undefined;
          }
        });
    };

    const fetchUserOptions = () => {
      store
        .dispatch("app-role/fetchUsers")
        .then((response) => {
          userOptions.value = response.data.users;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userOptions.value = undefined;
          }
        });
    };

    const fetchRole = () => {
      var name = props.userData.roles.name;
      if (!name) {
        name = props.userData.roles[0].name;
      }
      store
        .dispatch("app-role/fetchRole", { name: name })
        .then((response) => {
          props.userData.rolesPermission = response.data.permissions;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            props.userData.rolesPermission = undefined;
          }
        });
    };

    const updateDirectPermission = () => {
      directPermission.value = [];
      props.userData.directPermission.forEach((dp) => {
        if (dp.admin) directPermission.value.push(dp.module + ".admin");
        if (dp.create) directPermission.value.push(dp.module + ".create");
        if (dp.read) directPermission.value.push(dp.module + ".read");
        if (dp.view) directPermission.value.push(dp.module + ".view");
        if (dp.update) directPermission.value.push(dp.module + ".update");
        if (dp.delete) directPermission.value.push(dp.module + ".delete");
      });
    };

    onMounted(() => {
      fetchRoleOptions();
      fetchUserOptions();
      fetchRole();
      updateDirectPermission();
    });

    watch(
      () => props.userData.roles,
      (selection, prevSelection) => {
        fetchRole();
      },
      { immediate: true }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      userOptions,
      fetchRole,
      directPermission,
      updateDirectPermission,
      ability
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.space {
  padding-bottom: 4px;
}
</style>
